import React, { useState, useEffect } from 'react';
import MicrosoftLogin, {logout} from "react-microsoft-login";
import {useCookies} from 'react-cookie';
import DataTable, {createTheme} from 'react-data-table-component';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import { pdf, Page, Text as Text1, Font, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import { useMemo } from 'react';
import Header from './components/Header';

Font.register({family:'OpenSans',fonts:[
  {src:'https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8-VeJoCqeDjg.ttf'},
  {src:'https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN7rg-VeJoCqeDjg.ttf',fontStyle:'bold'},
]});

function App() {
  createTheme('dark',{background:'transparent'});
  const reports = [{reportid: 1,reportname: 'asd',}];
  const tempReport = [{reportid: 1,reportname: 'asd',}];
  const [msalInstance, onMsalInstanceChange] = useState();
  const [name,setName] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['creds']);
  const [myerror, setMyError] = useState();
  const [reportsAvailable, setReports] = useState(reports);
  const [selectedReport, setSelectedReport] = useState(tempReport);
  const [selectedReportName, setSelectedReportName] = useState();
  const [type, setType] = useState();

  useEffect(() => {
    if (selectedReport!==undefined && selectedReportName!==undefined) {
      if (type === "xls") {
        ExportXlsx(selectedReport, selectedReportName);
      } else if (type === "pdf") {
        var filename = selectedReportName + ".pdf";
        generatePdfDocument(filename, <MyDocument />);
      }
    }
  }, [selectedReport, selectedReportName]);

  useEffect(() => {
    if (name) {
      fetchReports(name[2],name[1]);
    }
  }, [name]);

  const styles = StyleSheet.create({
    page: {
      padding: 30,
    },
    container: {
      flex: 1,
      justifyContent:'center', 
      alignItems: 'center',
      flexDirection: 'column',
    },
    image: {
      marginBottom: 10,
    },
    leftColumn: {
      flexDirection: 'column',
      width: 170,
      paddingTop: 30,
      paddingRight: 15,
      '@media max-width: 400': {
        width: '100%',
        paddingRight: 0,
      },
      '@media orientation: landscape': {
        width: 200,
      },
    },
    footer: {
      width:'100%',
      fontSize: 6,
      fontFamily: 'OpenSans',
      textAlign: 'center',
      justifyContent:'center', alignItems: 'center',
      paddingTop: 5,
      borderTopWidth: 0.5,
      borderTopColor: 'gray',
      borderTopStyle: 'dashed',
      '@media orientation: landscape': {
        marginTop: 10,
      },
    },
    tableStyle: { 
      display: "table", 
      width: "auto", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0,
      marginTop:2
    }, 
    tableRow: { 
      margin: "auto", 
      width: "80%", 
      flexDirection: "row" 
    }, 
    tableCol: { 
      width: "120rem",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    }, 
    tableCell: { 
      fontFamily: 'OpenSans',
      margin: "auto", 
      marginVertical: 5, 
      marginHorizontal: 1,
      fontSize: 8,
      alignItems:'left'
    },
    tableHeader: { 
      fontFamily: 'OpenSans',
      fontStyle:'bold',
      margin: "auto", 
      marginTop: 5, 
      marginHorizontal: 1,
      fontSize: 8 
    }
  });

  const columns = [
    {name: 'Τίτλος Αναφοράς',selector:row => row.reportname,sortable:true},
    {
      cell: (row) => <button style={{borderColor:'gray.800',borderWidth:1,borderRadius:5,padding:5}} onClick={(e) => fetchSelectedReport(e, row.reportid, "pdf")}>PDF</button>,
      allowOverflow: true,
      button: true
    },
    {
      cell: (row) => <button style={{borderColor:'gray.800',borderWidth:1,borderRadius:5,padding:5}} onClick={(e) => fetchSelectedReport(e, row.reportid, "xls")}>XLS</button>,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => <button style={{borderColor:'gray.800',borderWidth:1,borderRadius:5,padding:5}} onClick={(e) => fetchSelectedReport(e, row.reportid, "docx")}>DOCX</button>,
      allowOverflow: true,
      button: true,
    },
  ];

  // Συνάρτηση για εξαγωγή σε μορφή docx
  const exportToDocx = (rowKey) => {
    // Υλοποιήστε την λογική εξαγωγής σε docx εδώ.
  };

  const handleLogin = (err, data, msal) => {
    // some actions
    if (!err && data) {
      onMsalInstanceChange(msal);
      setName([data.displayName,data.id,data.mail]);
      setCookie('creds',JSON.stringify({"username":data.mail,"userid":data.id}));
    }
  };

  const logoutHandler = () => {
    msalInstance.logout();
    setName();
    removeCookie('creds');
  };

  const formatDate = (date) => {  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  // Create Document Component
  const MyDocument = () => {
    // Create an array of column headers based on the selectedReport data
    const columnHeaders = selectedReport.length > 0 ? Object.keys(selectedReport[0]) : [];

    // Function to calculate the width of a text based on its content
    const getTextWidth = (text, fontSize = 8) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.font = `${fontSize}px OpenSans`; // Use the same font as in styles
      return ctx.measureText(text).width;
    };

    // Calculate column widths based on content and headers
    const columnWidths = columnHeaders.reduce((widths, header) => {
      const headerWidth = getTextWidth(header, 8); // Calculate header width

      const maxWidth = Math.max(
        headerWidth,
        ...selectedReport.map((record) => getTextWidth(String(record[header])))
      );
      widths[header] = Math.min(maxWidth, 150); // Limit max width to 150
      return widths;
    }, {});

    return (
      <Document title={selectedReportName} author={cookies.creds.username} creator={cookies.creds.username} producer={cookies.creds.username}>
        <Page size="A4" orientation="landscape" wrap="false">
          <Header reportname={selectedReportName} date={formatDate(new Date())} />
          <View style={styles.container}>
            <View style={styles.tableStyle}>
              <View style={styles.tableRow}>
                {columnHeaders.map((header, i) => (
                  <View style={{ ...styles.tableCol, width: columnWidths[header] }} key={i}>
                    <Text1 style={styles.tableHeader}>{header}</Text1>
                  </View>
                ))}
              </View>
              {selectedReport.map((record, i) => (
                <View style={styles.tableRow} key={i}>
                  {columnHeaders.map((header, j) => (
                    <View style={{ ...styles.tableCol, width: columnWidths[header] }} key={j}>
                      <Text1 style={styles.tableCell}>{record[header]}</Text1>
                    </View>
                  ))}
                </View>
              ))}
            </View>
          </View>
          <Text1 style={styles.footer} fixed>
            ΕΥΔ ΣΣ ΚΑΠ, Μονάδα Χρηματοοικονομικών Ροών και Πληροφοριακών Συστημάτων
          </Text1>
        </Page>
      </Document>
    );
  };

  const generatePdfDocument = async (fileName, pdfDocumentComponent) => {
    const blob = await pdf(pdfDocumentComponent).toBlob();
    saveAs(blob, fileName);
  };

  const ExportXlsx = (a, fileName) => {
    const newData = a.map((item) => {
      const newItem = { ...item };
      Object.keys(item).forEach((key) => {
        if (Array.isArray(item[key])) {
          newItem[key] = item[key].join(", ");
        } else if (["Εντάξεις", "Πληρωμές ΔΔ", "Πληρωμές ΕΣ", "Πληρωμές Δ.Δ.", "Πληρωμές Ε.Σ.","Πληρωμές ΔΔ 2023", "Πληρωμές ΕΣ 2023"].includes(key)) {
          // Replace "." with "" and convert to a number
          //newItem[key] = item[key].replaceAll(".", "");
        }
      });
      return newItem;
    });
    const ws = XLSX.utils.json_to_sheet(newData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { fileType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "xlsx" });
    saveAs(data, fileName + ".xlsx");
  };

  const fetchReports = (username,userid) => {
    // Εδώ θα πρέπει να προσθέσετε την λογική πιστοποίησης και τον έλεγχο για το αν είναι συνδεδεμένος ο χρήστης.
    if ("creds" in cookies) {
      var availableUrl = "https://app.agristats.eu/getReports?username="+username+"&userid="+userid;
    } else {
      var availableUrl = "https://app.agristats.eu/getReports?username=&userid=";
    }
    return fetch(availableUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.myerror) {
          setMyError(data.myerror);
          alert(data.myerror);
        }
        else{
          let temp = data.reports.sort((a, b) => a.reportid - b.reportid);
          setReports(temp);
        }
      })
      .catch(error => {console.log("test", error);});
  };

  const fetchSelectedReport = async (e, reportid, mytype) => {
    e.preventDefault();
    if ("creds" in cookies) {
      var reportUrl = "https://app.agristats.eu/getReport?username=" + cookies.creds.username + "&userid=" + cookies.creds.userid + "&reportid=" + reportid + "&type=" + mytype;
    } else {
      var reportUrl = "https://app.agristats.eu/getReport?username=&userid=&reportid=" + reportid;
    }
    try {
      const response = await fetch(reportUrl);
      const data = await response.json();
      if (data.myerror) {
        setMyError(data.myerror);
        alert(data.myerror);
      } else {
        await setSelectedReport(data.report);
        await setSelectedReportName(data.reportname);
        await setType(mytype);
      }
    } catch (error) {console.log("test", error);}
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems:'center',justifyContent:'center', width:'100%', height: '100vh', backgroundColor: '#222', color: '#fff'}}>
      {msalInstance ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '80%', height: '100vh', backgroundColor: '#222', color: '#fff' }}>
          <h1>Καλώς ήρθατε, {name[0]}!</h1>
          {reportsAvailable.length>1 && <DataTable columns={columns} data={reportsAvailable} pagination theme="dark"/>}
          <div>
            <button onClick={logoutHandler}>Αποσύνδεση</button>
          </div>
        </div>
      ) : (
        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center',justifyContent:'center', width:'100%', height: '100vh', backgroundColor: '#222', color: '#fff'}}>
          <h1>Καλώς ήρθατε στο εργαλείο αναφορών</h1>
          <h4>Η πρόσβαση επιτρέπεται μόνο σε πιστοποιημένους χρήστες με κατάλληλα δικαιώματα.</h4>
          <MicrosoftLogin clientId={process.env.REACT_APP_MICROSOFT_LOGIN} authCallback={handleLogin} withUserData="true" useLocalStorageCache="true"/>
        </div>
      )}
    </div>
  );
}

export default App;