import React, {Component} from 'react';
import {Link, Text, View, StyleSheet, Image} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    alignItems: 'stretch',
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
    justifyContent:'center',
    alignItems:'center'
    //textTransform: 'uppercase',
  },
  image: {
    marginVertical: 10,
    marginHorizontal: 10,
    width: 5,
    height: 5
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  name: {
    fontSize: 20,
    fontFamily: 'OpenSans',
    fontStyle:'bold'
  },
  subtitle: {
    fontSize: 10,
    justifySelf: 'flex-end',
    fontFamily: 'OpenSans',
  }
});

class Header extends Component {
  
  constructor(props){
    super(props);
  }

  formatDate= (date) => {  
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.detailColumn}>
          <Image style={styles.image} src="1.jpg" fixed="true"/>
          <Text style={styles.name}>{this.props.reportname}</Text>
          <Text style={styles.subtitle}>Ημερομηνία αναφοράς {this.props.date}</Text>
        </View>
        <Image style={styles.image} src="1.jpg" fixed="true"/>
      </View>
    );
  }
}

export default Header;